@import url('https://fonts.googleapis.com/css?family=Arial&display=swap');

.font-Arial{

    font-family:"Arial" ;

}
@import url('https://fonts.googleapis.com/css?family=Calibri&display=swap');

.font-Calibri{

    font-family:"Calibri" ;

}
@import url('https://fonts.googleapis.com/css?family=Cambria&display=swap');

.font-Cambria{

    font-family:"Cambria" ;

}
@import url('https://fonts.googleapis.com/css?family=Meiryo&display=swap');

.font-Meiryo{

    font-family:"Meiryo" ;

}
@import url('https://fonts.googleapis.com/css?family=Impact&display=swap');

.font-Impact{

    font-family:"Impact" ;

}
@import url('https://fonts.googleapis.com/css?family=Comic Sans&display=swap');

.font-Comic Sans{

    font-family:"Comic Sans" ;

}
@import url('https://fonts.googleapis.com/css?family=Georgia&display=swap');

.font-Georgia{

    font-family:"Georgia" ;

}
@import url('https://fonts.googleapis.com/css?family=Trebuchet MS&display=swap');

.font-Trebuchet-MS{

    font-family:"Trebuchet MS" ;

}
@import url('https://fonts.googleapis.com/css?family=Microsoft Sans Serif&display=swap');

.font-Microsoft-Sans-Serif{

    font-family:"Microsoft Sans Serif" ;

}
.asa{
    color:purple;
}
