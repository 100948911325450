.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@import url('https://fonts.googleapis.com/css?family=Cambria&display=swap');
@font-face {
  font-family: 'trashimclm-bold';
  src: url('assets/fonts/trashimclm-bold-webfont.ttf'); /* IE9 Compat Modes */
}
.applyDefaultFont{
  font-family: 'Cambria' !important;
  font-weight: bold;
}
.headerLinkActive{
  font-size: 18px;
  color: #FB8530;
}
.headerLink{
  color: #6F6F6F;
}
.recordButton{
  display:flex;
  height:60px;
  width:60px;
  background-color:#FB8530;
  border-radius:60px;
  border:0;
 justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 992px) {
  .headerLinkActive{
    font-size: 14px !important;;

  }
  .headerLink{
    font-size: 14px !important;
  }
}
.textAreaButton{
  width: 23px;
  height: 23px;
  transition-timing-function: linear;
  -webkit-transition: width 1s,height 1s;
  transition: width 1s,height 1s;

}
.textAreaButton:hover{
  width: 27px;
  height: 27px;
  transition-timing-function: linear;
  -webkit-transition: width 1s,height 1s;
  transition: width 1s,height 1s;
}
.appSelect{
  font-family: 'Cambria' !important;
  -webkit-appearance: none;
  appearance: none;
  font-size: 16px;
  font-weight: bold;
}
.AppTextArea{

}
